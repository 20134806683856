import Vuex from 'vuex'
import { getSpeakerBySlug } from '@/services/api/translatesAndContent'
import * as contentAPI from '@/services/api/translatesAndContent'

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: {
    speaker: {}
  },

  actions: {
    async fetchSpeaker ({ commit }, slug) {
      const { data } = await getSpeakerBySlug(slug)
      if (!data) throw new Error(404)
      commit('updateSpeaker', data)
    },

    async getSpeakerFromContent ({ commit }, slug) {
      const { data } = await contentAPI.getSpeakers({ onMain: true })
      const speaker = data.find(el => el.slug === slug)
      commit('updateSpeaker', speaker)
    }
  },

  mutations: {
    updateSpeaker (state, speaker) {
      state.speaker = speaker
    }
  }
})
