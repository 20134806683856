var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-speaker" },
    [
      _c("Hero", [
        _c("div", { staticClass: "hero-inner" }, [
          _vm.activeEvent
            ? _c("div", { staticClass: "hero-inner__text" }, [
                _vm._v(" " + _vm._s(_vm.activeEvent.title) + " ")
              ])
            : _vm._e()
        ])
      ]),
      _c("v-container", [
        _c("div", { staticClass: "speaker" }, [
          _c("div", { staticClass: "speaker__inner" }, [
            _c("div", { staticClass: "speaker__left" }, [
              _c(
                "div",
                { staticClass: "speaker__avatar" },
                [
                  _c("Avatar", {
                    attrs: {
                      src: _vm.getLinkByFileName(
                        "speakers",
                        "large",
                        _vm.speaker.photo
                      ),
                      alt: _vm.speaker.name,
                      tile: ""
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "speaker__right" }, [
              _c("div", { staticClass: "speaker__info" }, [
                _c("div", { staticClass: "speaker__info-top" }, [
                  _c(
                    "div",
                    {
                      staticClass: "speaker__name",
                      class: {
                        "speaker__name--small":
                          _vm.checkMaxWordLength(_vm.speaker.name) ||
                          _vm.checkMaxWordLength(_vm.speaker.patronymic)
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.speaker.name) + " "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.speaker.patronymic) + " ")
                    ]
                  ),
                  _c("div", { staticClass: "speaker__location" }, [
                    _vm._v(" " + _vm._s(_vm.speaker.location) + " ")
                  ]),
                  _c("div", { staticClass: "speaker__position" }, [
                    _c("div", { staticClass: "speaker__position-icon" }, [
                      _c(
                        "div",
                        { staticClass: "crown" },
                        [
                          _c("div", { staticClass: "crown__text" }),
                          _c("c-img", {
                            attrs: {
                              src:
                                "assets/img/" +
                                _vm.themePath +
                                "/crown-empty.svg",
                              alt: ""
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "speaker__position-text" }, [
                      _vm._v(" " + _vm._s(_vm.speaker.position) + " ")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "speaker__socials" }, [
                  _c(
                    "div",
                    { staticClass: "socials-list speaker__socials-list" },
                    [
                      _vm.speaker.instagram
                        ? _c("a", {
                            staticClass:
                              "socials-list__link socials-list__link_instagram",
                            attrs: {
                              href: _vm.speaker.instagram,
                              target: "_blank"
                            }
                          })
                        : _vm._e(),
                      _vm.speaker.facebook
                        ? _c("a", {
                            staticClass:
                              "socials-list__link socials-list__link_facebook",
                            attrs: {
                              href: _vm.speaker.facebook,
                              target: "_blank"
                            }
                          })
                        : _vm._e(),
                      _vm.speaker.telegram
                        ? _c("a", {
                            staticClass:
                              "socials-list__link socials-list__link_telegram",
                            attrs: {
                              href: _vm.speaker.telegram,
                              target: "_blank"
                            }
                          })
                        : _vm._e(),
                      _vm.speaker.site
                        ? _c("a", {
                            staticClass:
                              "socials-list__link socials-list__link_globe",
                            attrs: { href: _vm.speaker.site, target: "_blank" }
                          })
                        : _vm._e(),
                      _vm.speaker.email
                        ? _c("a", {
                            staticClass:
                              "socials-list__link socials-list__link_mail",
                            attrs: { href: "mailto:" + _vm.speaker.email }
                          })
                        : _vm._e()
                    ]
                  )
                ]),
                _vm.speaker.quote
                  ? _c("div", { staticClass: "speaker__quote" }, [
                      _c("div", { staticClass: "quote-line" }, [_vm._v(" “ ")]),
                      _c("div", { staticClass: "speaker__quote-text" }, [
                        _vm._v(" " + _vm._s(_vm.speaker.quote) + " ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "quote-line quote-line_inverted" },
                        [_vm._v(" ” ")]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }