<template>
  <div class="page-speaker">
    <Hero>
      <div class="hero-inner">
        <div v-if="activeEvent" class="hero-inner__text">
          {{ activeEvent.title }}
        </div>
        <!-- <c-img
          class="hero-inner__image hero-inner__image_1"
          src="assets/img/profile-user/bg-text.png"
          alt="Winter"
        />
        <c-img
          class="hero-inner__image hero-inner__image_2"
          src="assets/img/profile-user/bg-text.png"
          alt="Winter"
        /> -->
      </div>
    </Hero>
    <v-container>
      <div class="speaker">
        <div class="speaker__inner">
          <div class="speaker__left">
            <div class="speaker__avatar">
              <Avatar
                :src="getLinkByFileName('speakers', 'large', speaker.photo)"
                :alt="speaker.name"
                tile
              />
            </div>
          </div>
          <div class="speaker__right">
            <div class="speaker__info">
              <div class="speaker__info-top">
                <div class="speaker__name" :class="{'speaker__name--small' : (checkMaxWordLength(speaker.name) || checkMaxWordLength(speaker.patronymic))}">
                  {{ speaker.name }} <br>
                  {{ speaker.patronymic }}
                </div>
                <div class="speaker__location">
                  {{ speaker.location }}
                </div>
                <div class="speaker__position">
                  <div class="speaker__position-icon">
                    <div class="crown">
                      <div class="crown__text" />
                      <c-img :src="`assets/img/${themePath}/crown-empty.svg`" alt="" />
                    </div>
                  </div>
                  <div class="speaker__position-text">
                    {{ speaker.position }}
                  </div>
                </div>
              </div>
              <div class="speaker__socials">
                <div class="socials-list speaker__socials-list">
                  <a
                    v-if="speaker.instagram"
                    :href="speaker.instagram"
                    target="_blank"
                    class="socials-list__link socials-list__link_instagram"
                  />
                  <a
                    v-if="speaker.facebook"
                    :href="speaker.facebook"
                    target="_blank"
                    class="socials-list__link socials-list__link_facebook"
                  />
                  <a
                    v-if="speaker.telegram"
                    :href="speaker.telegram"
                    target="_blank"
                    class="socials-list__link socials-list__link_telegram"
                  />
                  <a
                    v-if="speaker.site"
                    :href="speaker.site"
                    target="_blank"
                    class="socials-list__link socials-list__link_globe"
                  />
                  <a
                    v-if="speaker.email"
                    :href="`mailto:${speaker.email}`"
                    class="socials-list__link socials-list__link_mail"
                  />
                </div>
              </div>
              <div v-if="speaker.quote" class="speaker__quote">
                <div class="quote-line">
                  “
                </div>
                <div class="speaker__quote-text">
                  {{ speaker.quote }}
                </div>
                <div class="quote-line quote-line_inverted">
                  ”
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar'
import Hero from '@/components/Hero'
import store from './store'
import { mapActions, mapState } from 'vuex'
import EventBus from '@/EventBus'
import { getLinkByFileName } from '@/utils/mediaHelpers'
import businessEventService from '@/services/businessEvent'
import { getThemePath } from '@/utils/common'

export default {
  name: 'PageSpeaker',

  store,

  components: { Avatar, Hero },

  data () {
    return {
      activeEvent: null
    }
  },

  computed: {
    ...mapState({
      speaker: 'speaker'
    }),

    themePath () {
      return getThemePath()
    }
  },

  async created () {
    this.getSpeaker()
    this.activeEvent = await businessEventService.getActiveEvent()

    EventBus.subscribe('language:update', this.langChanged)
  },

  beforeDestroy () {
    EventBus.unsubscribe('language:update', this.langChanged)
  },

  methods: {
    ...mapActions([
      'fetchSpeaker'
    ]),

    langChanged () {
      this.getSpeaker()
    },

    checkMaxWordLength (words) {
      return words && words.split(' ').some(i => i.length >= 12)
    },

    async getSpeaker () {
      const speakerSlug = this.$route.params.id
      try {
        await this.fetchSpeaker(speakerSlug)
      } catch (err) {
        if (err.response?.status === '404' || err.message === 404) {
          this.$router.push('/404')
          return
        }
        throw err
      }
    },

    getLinkByFileName
  }
}
</script>

<style lang="scss">
@import "./styles";
</style>
